import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import { withNamespaces } from 'react-i18next'
import { Section, SliderBasic } from 'inno-components'
import { Container, Row, Col } from 'react-grid-system'

import SecondaryLayout from '../layouts/SecondaryLayout'
import Classes from '../../styles/classes'

const styles = StyleSheet.create({
  menu: {
    alignItems: 'center',
    backgroundColor: '#333',
    borderRadius: 0,
    padding: '3px',
    zIndex: 9,
  },
  imageContainer: {
    padding: '1em',
  },
})

const InstallationEcran = ({ t }) => (
  <StaticQuery
    query={graphql`
      query InstallationEcranImageQuery {
        cm1: file(relativePath: { eq: "home/CM1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cm2: file(relativePath: { eq: "home/CM2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        del1: file(relativePath: { eq: "home/DEL1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        del2: file(relativePath: { eq: "home/DEL2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        im3: file(relativePath: { eq: "home/IM3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <SecondaryLayout subtitle={t('installation_ecrans.titre')}>
          <Helmet
            title={t('installation_ecrans.head.title')}
            meta={[
              {
                name: 'description',
                content: t('installation_ecrans.head.description'),
              },
            ]}
          />
          <Section className={css(Classes.section)}>
            <Container>
              <Row>
                <Col>
                  <p>{t('installation_ecrans.paragraphes.0')}</p>
                  <p>{t('installation_ecrans.paragraphes.1')}</p>
                  <p>{t('installation_ecrans.paragraphes.2')} </p>
                  <SliderBasic
                    slidesToShow={4}
                    autoplaySpeed={2000}
                    slidesToScroll={1}
                    autoplay
                  >
                    <div className={css(styles.imageContainer)}>
                      <Img fluid={data.cm1.childImageSharp.fluid} />
                    </div>
                    <div className={css(styles.imageContainer)}>
                      <Img fluid={data.cm2.childImageSharp.fluid} />
                    </div>
                    <div className={css(styles.imageContainer)}>
                      <Img fluid={data.del1.childImageSharp.fluid} />
                    </div>
                    <div className={css(styles.imageContainer)}>
                      <Img fluid={data.del2.childImageSharp.fluid} />
                    </div>
                    <div className={css(styles.imageContainer)}>
                      <Img fluid={data.im3.childImageSharp.fluid} />
                    </div>
                  </SliderBasic>
                </Col>
              </Row>
            </Container>
          </Section>
        </SecondaryLayout>
      )
    }}
  />
)

export default withNamespaces('home')(InstallationEcran)
